<template>
  <div id="app">
    <DocValidator
        :documents="docs"
        :token="token"
        :selfie="isSelfie"
        :autoCapture="true"
        :language="lang"
        @success="handleSuccess"
        @cancel="handleCancel"
        @failure="handleFailure"
    >
    </DocValidator>

  </div>
</template>

<script>


export default {
	name: 'App',
	components: {
	},
	created(){
		//passport
		const urlParams = new URLSearchParams(window.location.search);
		this.docType = (urlParams.get("doc_card_id") === "1" ? 'idcard' : 'passport');
		this.token = urlParams.get("token");
		//this.lang = document.getElementById("hidLang").value.toLowerCase();
		
		this.docs = [{type: this.docType, issuing: (urlParams.get("iss"))}, {type: 'driver', issuing: (urlParams.get("iss"))}];
		
		this.isSelfie = (urlParams.get("fromAdm") === "1" ? false : true);
		this.lang = urlParams.get("lang");
		this.dataAuth = urlParams.get("auth");
		this.fromAdm = urlParams.get("fromAdm");
		//this.checkinUrl = "https://www.nizacars.com/checkin/requestedCheckIn.aspx";
		this.checkinUrl = "https://nizacarsweb.nfactorial.eu/checkin/requestedCheckIn.aspx";
		//this.cancelUrl = (urlParams.get("fromAdm") === "1" ? ("https://manager.nizacars.com/gestion-reservas.aspx?q=" + urlParams.get("numRes")) : "https://nizacarsweb.nfactorial.eu/checkin/requestedCheckIn.aspx");
		this.cancelUrl = (urlParams.get("fromAdm") === "1" ? ("https://nizacarsadmin.nfactorial.eu/gestion-reservas.aspx?q=" + urlParams.get("numRes")) : "https://nizacarsweb.nfactorial.eu/checkin/requestedCheckIn.aspx");
		},
	data: function(){
	return{
		token: "",
		lang: "",
	}
	},
	mounted(){
		
	},
	methods: {
		handleSuccess(event, value) 
		{
			window.top.location.replace(this.checkinUrl + "?token=" + this.token + "&type=success&" + this.dataAuth);
			console.log(event + " " + value);
			//alert("Finalizado con éxito");
		},
		handleCancel(event) {
			if (this.fromAdm === "1")
			{
				window.top.location.replace(this.cancelUrl);
			}
			else
			{
				window.top.location.replace(this.checkinUrl + "?token=" + this.token + "&type=cancel&" + this.dataAuth);
			}	
			
			console.log(event);
		},
		handleFailure(event, value) {
			//alert("Hubo un error");
			window.top.location.replace(this.checkinUrl + "?token=" + this.token + "&type=failure&" + this.dataAuth);
			console.log(event + " " + value);
		}
	},
}


</script>

<style>

@import "../node_modules/@moviondevteam/docvalidator/dist/movionValidator.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
