import Vue from 'vue'
import App from './App.vue'
import docValidator from "@moviondevteam/docvalidator";

Vue.config.productionTip = false
Vue.component("DocValidator", docValidator);

new Vue({
  render: h => h(App),
}).$mount('#app')
